import React from "react";
import SEO from "../components/utilities/SEO";
import Layout from "../components/layout/Layout";
import ContentTitle from "../components/content-title/ContentTitle";
import AxeSrc from "../images/Axe.svg";
import LogsSrc from "../images/Logs.svg";
import OstSrc from "../images/Metsakinnistute ost.svg";
import KavaSrc from "../images/Metsamajandus kava.svg";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import LinkButton from "../components/link-button/LinkButton";
import ContentWrapper from "../components/content-wrapper/ContentWrapper";
import "./index.scss";

const IndexPage = () => (
  <Layout>
    <SEO title="Kodu" />
    <ContentWrapper>
      <Col xs={12} className="mb-4">
        <ContentTitle title={"Teenused"} />
      </Col>
      <Col
        xs={12}
        md={6}
        lg={3}
        className="d-flex flex-column align-items-center text-center mt-4"
      >
        <img src={AxeSrc} className="service-icon" alt="" />
        <h3 className="mt-2 w-100">Raieõiguse ost</h3>
      </Col>
      <Col
        xs={12}
        md={6}
        lg={3}
        className="d-flex flex-column align-items-center text-center mt-4"
      >
        <img src={LogsSrc} className="service-icon" alt="" />
        <h3 className="mt-2 w-100">Metsamaterjali ost</h3>
      </Col>
      <Col
        xs={12}
        md={6}
        lg={3}
        className="d-flex flex-column align-items-center text-center mt-4"
      >
        <img src={OstSrc} className="service-icon" alt="" />
        <h3 className="mt-2 w-100">Metsakinnistute ost</h3>
      </Col>
      <Col
        xs={12}
        md={6}
        lg={3}
        className="d-flex flex-column align-items-center text-center mt-4"
      >
        <img src={KavaSrc} className="service-icon" alt="" />
        <h3 className="mt-2 w-100">Metsamajandus kavad</h3>
      </Col>
    </ContentWrapper>
    <Row>
      <Col xs={12}>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <span
            className="text-white text-center mb-3"
            style={{ fontSize: "1.25rem" }}
          >
            Ei ole kindel millist teenust vaja või soovite infot? Võtke meiega
            ühendust:
          </span>
          <LinkButton dest={"/kontakt"}>Kontakt</LinkButton>
        </div>
      </Col>
    </Row>
  </Layout>
);

export default IndexPage;
