import React from "react";
import { Link } from "gatsby";
import "./LinkButton.scss";

const LinkButton = ({ dest, children }) => (
  <Link
    className="link-button text-decoration-none font-weight-bold p-1 px-5"
    to={dest}
  >
    {children}
  </Link>
);

export default LinkButton;
